@tailwind components;
@tailwind utilities;

// variable
// primary-color: #003271;

input,
textarea {
  box-shadow: none !important;
}
